exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-politica-de-redes-sociales-js": () => import("./../../../src/pages/politica-de-redes-sociales.js" /* webpackChunkName: "component---src-pages-politica-de-redes-sociales-js" */),
  "component---src-pages-verificacion-de-email-js": () => import("./../../../src/pages/verificacion-de-email.js" /* webpackChunkName: "component---src-pages-verificacion-de-email-js" */),
  "component---src-templates-blog-entry-js": () => import("./../../../src/templates/blog-entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */)
}

