module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"troncal-radio","short_name":"starter","start_url":"/","background_color":"#061a35","theme_color":"#061a35","display":"minimal-ui","icons":[{"src":"meta/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"meta/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"meta/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"meta/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"meta/favicon-196x196.png","sizes":"196x196","type":"image/png"},{"src":"meta/mstile-310x310.png","sizes":"310x310","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
